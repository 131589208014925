@import "core-variables";
.modal {
  .modal-content {
    .modal-header {
      .modal-title {
        color: $black;
      }
    }
  }
}
