.btn-primary {
  background-color: $button-primary-color;
  color: $white;
  border-color: $button-primary-color;

  &:hover, &:active {
    background-color: $black;
    color: $white;
    border-color: $black;
  }
}

.btn-group {
  & > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 50px;
    margin: 2px;
  }

  & > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    margin: 2px;
  }

  & > .btn:last-child:not(:first-child),
  & > .dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    margin: 2px;
  }
}

.btn-group-vertical {
  .btn {
    margin: 2px;
    &:first-child {
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
    &:last-child {
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
    }
    &:not(:first-child):not(:last-child):not(.dropdown-toggle) {
      border-radius: 50px;
    }
  }
}

.btn-lg, .btn-group-lg > .btn  {
  padding: 0.6875rem 2.5rem;
}

.arrow_enabled:hover, .table_standard .arrow_enabled:hover {
  color: $white;
  background-color: $black;
  border-color: $black;
}
