form.login {
  .card-footer {
    background-color: $brand-primary;
  }
  .form-control {
    color: $black;
  }
}

form.login .card-footer,
form.login ul.team li div.employee-card div.employee-actions,
ul.team li div.employee-card form.login div.employee-actions {
  background-color: $brand-primary;
}
