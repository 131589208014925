body:not(.top-nav).sidebar-nav {
  .sidebar {
    ul.nav {
      li.nav-item {
        a.nav-link {
          &:hover {
            color: $black !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}
