.card {
  border: 1px solid $card-border-color;
  .card-header {
    color: $white;
  }
  .card-block:after {
    display: none;
  }
}

.card-footer {
  background-color: $gray-lightest;
}

.card-group .card:last-child {
  border-right: 1px solid $card-border-color;
}

form.login .card {
  border: none;
}

form.login .form-control {
  border: 1px solid $brand-primary;
}