// Fonts styles
// ---------------

// Fontawesome
$fa-font-path: "../fonts/";
@import "../../node_modules/font-awesome/scss/font-awesome";

// Simple line icons
$simple-line-font-path: "../fonts/";
@import "../../node_modules/simple-line-icons/scss/simple-line-icons";

// Flag icons css
$flag-icon-css-path: "../images/flags";
@import "../../node_modules/flag-icon-css/sass/flag-icon";

// MK Iconfont
$mk-font-path: "../fonts/";
@import "../../node_modules/@mehrkanal/mk-fontbuilder/private/scss/mk-font";

// Project Font
// ------------
$font-path: "../fonts/";

@font-face {
  font-family: 'Read';
  font-weight: bold;
  src: url($font-path + 'Read-Bold_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: bold;
  font-style: italic;
  src: url($font-path + 'Read-BoldItalic_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: normal;
  font-style: italic;
  src: url($font-path + 'Read-Italic_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: 300;
  src: url($font-path + 'Read-Light_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: 300;
  font-style: italic;
  src: url($font-path + 'Read-LightItalic_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: 500;
  src: url($font-path + 'Read-Medium_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: 500;
  font-style: italic;
  src: url($font-path + 'Read-MediumItalic_V3000.ttf') format('truetype');
}
@font-face {
  font-family: 'Read';
  font-weight: normal;
  src: url($font-path + 'Read-Regular_V3000.ttf') format('truetype');
}

