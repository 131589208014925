@import "core-variables";
.popover-hotline {
  background-color: $brand-primary;
}

.popover-title {
  color: $white;
}

.popover-hotline .popover-body,
.popover-hotline .gantt_tooltip {
  background-color: $brand-primary;
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after{
  background-color: $brand-primary !important;
}
