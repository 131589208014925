header.navbar {
  -webkit-box-shadow:0px 1px 1px $border-color;
 -moz-box-shadow:0px 1px 1px $border-color;
 box-shadow:0px 1px 1px $border-color;
}

.navbar-avatar {
  span {
    top: -17px;
    left: 3px;
    font-size: 17px;
  }
}

